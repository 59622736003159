import React from "react";
import CustomerInfoSection
    from "src/pages/dashboarSection/orderDetails/sections/details/components/customerInfoSection";
import DetailsSection from "src/pages/dashboarSection/orderDetails/sections/details/components/detailsSection";
import DriverInfoSection from "src/pages/dashboarSection/orderDetails/sections/details/components/driverInfoSection";
import MapSection from "src/pages/dashboarSection/orderDetails/sections/details/components/mapSection";
import StopsSection from "src/pages/dashboarSection/orderDetails/sections/details/components/stopsSection";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderDetailsTabSections} from "../../orderDetailsLayout";
import {DirectionsSection} from "./components/directionsSection";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails | undefined
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    currentActiveTab: OrderDetailsTabSections
}

export default function DetailsContentLayout({order, currentActiveTab, setOrder}: Props) {
    if (!order) return null

    return <div
        className={`${currentActiveTab !== OrderDetailsTabSections.details && 'hidden'} flex flex-col lg:flex-row gap-[28px] lg:gap-[4%]`}>
        <section className={'flex-none w-full lg:w-[60%]'}>
            <MapSection order={order}/>
        </section>

        <section className={'flex-1'}>
            <div className={'md:hidden'}><DirectionsSection order={order}/></div>
            <CustomerInfoSection order={order}/>
            <DetailsSection order={order}/>
            <DriverInfoSection order={order} setOrder={setOrder}/>
            <StopsSection order={order}/>
        </section>
    </div>
}