import React from "react";
import {ScrollArea, ScrollBar} from "src/components/scrollArea";
import Tabs from "src/pages/dashboarSection/orderDetails/buttons/buttonLayout/tabs";
import {OrderDetailsTabSections} from "src/pages/dashboarSection/orderDetails/orderDetailsLayout";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import {useNavigate} from "react-router-dom";
import useDialog, {DialogElementType} from "../../../layout/popUps/dialog/useDialog";

interface Props {
    order: OrderDetails
    currentActiveTab: OrderDetailsTabSections
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
}

export default function MobileDetailsHeader({order, currentActiveTab, setOrder}: Props) {
    const {isScrolled} = useViewport();
    const {open} = useDialog()


    const navigate = useNavigate()

    return <section
        className={`${!isScrolled && 'shadow-light'} flex-none px-padding sticky top-0 pt-8  md:shadow-none pb-6 bg-background z-30 flex flex-col gap-4`}>

        <div className={'flex flex-row justify-between items-center w-full'}>

            <span
                className={'w-10 h-10 rounded-full grid items-center place-content-center bg-[#F7F7F7] cursor-pointer'}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(`/loads/booked`)
                }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10H4.1665" stroke="#222222" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M9.99984 15.8327L4.1665 9.99935L9.99984 4.16602" stroke="#222222" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>

            <p className={'text-[#222222] text-[20px] leading-[28px] font-semibold'}>{`Load #${order.sid}`}</p>

            <span
                onClick={(e) => {
                    open(DialogElementType.contactSupport, order, 'Partner Support Assistance')
                }}
                className={'w-10 h-10 rounded-full grid items-center place-content-center bg-[#F7F7F7] cursor-pointer'}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_17961_54825)">
                        <path
                            d="M9.99984 18.3327C14.6022 18.3327 18.3332 14.6017 18.3332 9.99935C18.3332 5.39698 14.6022 1.66602 9.99984 1.66602C5.39746 1.66602 1.6665 5.39698 1.6665 9.99935C1.6665 14.6017 5.39746 18.3327 9.99984 18.3327Z"
                            stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M7.5752 7.50047C7.77112 6.94353 8.15782 6.4739 8.66682 6.17475C9.17583 5.87561 9.77427 5.76626 10.3562 5.86607C10.9381 5.96588 11.4659 6.26841 11.8461 6.72008C12.2263 7.17175 12.4344 7.74341 12.4335 8.33381C12.4335 10.0005 9.93353 10.8338 9.93353 10.8338"
                            stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 14.166H10.0083" stroke="#222222" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_17961_54825">
                            <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </span>
        </div>

        <ScrollArea className={"w-[95vw] sm:w-full"}>
            <Tabs order={order} currentActiveTab={currentActiveTab}/>
            <ScrollBar orientation="horizontal" className={'hidden'}/>
        </ScrollArea>
    </section>
}