import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke?: string
}


const MobileProfileIcon = ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 21 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path d="M3.8335 10H17.1668" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M3.8335 5H17.1668" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M3.8335 15H17.1668" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round"
              stroke-linejoin="round"/>
    </svg>
}

export {MobileProfileIcon}