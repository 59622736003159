import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ForgotPassword from "src/pages/authSection/forgotPassword/forgotPassword";
import LayoutAuth from "src/pages/authSection/layout/layoutAuth";
import Login from "src/pages/authSection/login/login";
import Register from "src/pages/authSection/register/register";
import ThankYouScreen from "src/pages/authSection/register/thankYouScreen";
import SetUpPassword from "src/pages/authSection/setupPassword/setUpPassword";
import MobileAccountSettings from "src/pages/dashboarSection/accountSettings/mobileAccountSettings";
import LoginAndSecurity from "src/pages/dashboarSection/accountSettings/userAccount/sections/LoginAndSecurity";
import PersonalDetails from "src/pages/dashboarSection/accountSettings/userAccount/sections/personalDetails";
import LayoutMain from "src/pages/dashboarSection/layout/layoutMain";
import OrderDetailsLayout from 'src/pages/dashboarSection/orderDetails/orderDetailsLayout';
import SearchLayout from "src/pages/dashboarSection/search/searchLayout/searchLayout";
import routes from "src/utils/defaults/routes";
import CompanyAccountLayout from './pages/dashboarSection/accountSettings/companyAccount/companyAccountLayout';
import CompanyDetails from './pages/dashboarSection/accountSettings/companyAccount/sections/companyDetails';
import PaymentsAndPayouts from './pages/dashboarSection/accountSettings/companyAccount/sections/paymentsAndPayouts';
import UserAccountLayout from './pages/dashboarSection/accountSettings/userAccount/userAccountLayout';
import Calendar from './pages/dashboarSection/calendar/calendar';
import FleetManagement from './pages/dashboarSection/fleetManagement/fleetManagement';
import Inbox from './pages/dashboarSection/inbox/inbox';
import MyLoads from './pages/dashboarSection/myLoads/myLoads';
import Main from './pages/main';
import CheckEmail from "./pages/authSection/checkEmail/checkEmail";
import InviteSetUpPassword from "./pages/authSection/invite-setup-password/setUpPassword";

function App() {
    return <BrowserRouter>
        <Routes>
            <Route path={routes.main} element={<Main/>}/>

            <Route path={routes.login} element={<LayoutAuth><Login/></LayoutAuth>}/>
            <Route path={routes.signUp} element={<LayoutAuth><Register/></LayoutAuth>}/>
            <Route path={routes.forgotPassword} element={<LayoutAuth><ForgotPassword/></LayoutAuth>}/>
            <Route path={routes.thankYouScreen} element={<LayoutAuth><ThankYouScreen/></LayoutAuth>}/>
            <Route path={routes.checkEmailRoute} element={<LayoutAuth><CheckEmail/></LayoutAuth>}/>

            <Route path={`${routes.registrationToken}/:token`} element={<LayoutAuth><SetUpPassword/></LayoutAuth>}/>
            <Route path={`${routes.invite}/:token`} element={<LayoutAuth><InviteSetUpPassword/></LayoutAuth>}/>

            <Route path={routes.search} element={<LayoutMain><SearchLayout/></LayoutMain>}/>
            <Route path={`${routes.loads}/:section`} element={<LayoutMain><MyLoads/></LayoutMain>}/>

            <Route path={`${routes.loads}/:id/:section`} element={<LayoutMain><OrderDetailsLayout/></LayoutMain>}/>

            <Route path={routes.fleetManagement} element={<LayoutMain><FleetManagement/></LayoutMain>}/>

            <Route path={routes.mobileAccountSettings} element={<LayoutMain><MobileAccountSettings/></LayoutMain>}/>

            <Route path={routes.companyDetails} element={<LayoutMain><CompanyAccountLayout><CompanyDetails/></CompanyAccountLayout></LayoutMain>}/>
            <Route path={routes.companyPaymentsAndPayouts} element={<LayoutMain><CompanyAccountLayout><PaymentsAndPayouts/></CompanyAccountLayout></LayoutMain>}/>

            <Route path={routes.userPersonalDetails} element={<LayoutMain><UserAccountLayout><PersonalDetails/></UserAccountLayout></LayoutMain>}/>
            <Route path={routes.userLoginAndSecurity} element={<LayoutMain><UserAccountLayout><LoginAndSecurity/></UserAccountLayout></LayoutMain>}/>

            <Route path={routes.calendar} element={<LayoutMain><Calendar/></LayoutMain>}/>
            <Route path={routes.inbox} element={<LayoutMain><Inbox/></LayoutMain>}/>

        </Routes>
    </BrowserRouter>
}

export default App;
