import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormMessage} from "src/components/form";
import {setPassword} from "src/utils/apiCalls/setPassword";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import checkValues, {FormCheck} from "src/utils/stringHandlers/checkValues";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {RegisterRequest} from "src/utils/types/registerRequest";
import setUpPasswordSchema from "src/utils/zodSchemas/setUpPasswordSchema";
import * as z from "zod";
import {PasswordInputOffLabel} from "../../../components/passwordInputOffLabel";

export default function InviteSetUpPassword() {
    const {token} = useParams();
    const navigate = useNavigate();
    const [formChecker, setFormChecker] = useState<FormCheck | null>(null)

    useEffect(() => {
        if (token) {
            form.setValue('token', token)
        }
    }, [token]);

    const form = useForm<z.infer<typeof setUpPasswordSchema>>({
        resolver: zodResolver(setUpPasswordSchema),
        defaultValues: {
            token: "",
            password: "",
            repeatPassword: ""
        }
    });

    async function onSubmit(values: z.infer<typeof setUpPasswordSchema>) {

        const credentials: RegisterRequest = {
            token: values.token,
            password: values.password
        }

        const res = await setPassword(credentials);
        if (res === 200) {
            navigate(routes.main)
        } else {
            if (isInstanceOf<ErrorMessage>(res, 'message')) {
                handleResponseError(res);
            }
        }
    }

    if (token) {
        return <div className={"flex flex-col gap-0"}>
            <div className={'flex flex-col gap-2 pb-[32px]'}>
                <h1 className={"font-inter text-[#222222] text-[24px] leading-[32px] font-medium"}>You have received an invitation to join the team.</h1>
                <p className={'font-inter text-[#717171] text-[14px] leading-[20px] font-normal'}>Please create a strong password that is difficult to guess and does not include any personal information.</p>
            </div>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={"flex flex-col gap-0"}>
                        <FormField
                            control={form.control}
                            name="password"
                            render={({field}) => (
                                <FormItem className={"flex-1 pb-6"}>

                                    {form.getFieldState('password').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}

                                    <FormControl>
                                        <PasswordInputOffLabel
                                            {...field}
                                            autoComplete={'off'}
                                            label={'Password'}
                                            value={form.getValues('password')}
                                            onInputCapture={() => form.clearErrors()}
                                            isInvalid={form.getFieldState('password').invalid}
                                            onChange={(e) => form.setValue('password', e.target.value)}
                                            onInput={event => setFormChecker(checkValues(event.currentTarget.value))}
                                            isInputOn={form.getValues('password').length !== 0}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="repeatPassword"
                            render={({field}) => (
                                <FormItem className={"flex-1 pb-6"}>
                                    {form.getFieldState('repeatPassword').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                    <FormControl>
                                        <PasswordInputOffLabel
                                            {...field}
                                            autoComplete={'off'}
                                            label={'Confirm password'}
                                            value={form.getValues('repeatPassword')}
                                            onInputCapture={() => form.clearErrors()}
                                            isInvalid={form.getFieldState('repeatPassword').invalid}
                                            onChange={(e) => form.setValue('repeatPassword', e.target.value)}
                                            isInputOn={form.getValues('repeatPassword').length !== 0}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <ul className={'grid grid-cols-2 pb-6 gap-y-3'}>
                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isLengthCorrect ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                    <path
                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                </svg>
                                At least 8 characters

                            </li>
                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isUpperPlusLowerLettersPresent ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                    <path
                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                </svg>

                                Upper + lowercase letters
                            </li>
                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isAtLeastOneNumber ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                    <path
                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                </svg>

                                At least 1 number
                            </li>
                            <li className={`flex flex-row gap-2 items-center ${!formChecker && '!text-[#717171] !stroke-[#717171]'} ${formChecker?.isAtLeastOneSpecialCharacter ? 'text-[#29845a] stroke-[#29845a]' : 'text-[#e51c00] stroke-[#e51c00]'} font-inter text-[12px] leading-[16px] font-normal`}>
                                <svg width="16" height="16" viewBox="0 0 17 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5"/>
                                    <path
                                        d="M6.94109 9.58847L11.5291 5L12.2353 5.70576L6.94109 11L3.76465 7.82356L4.47041 7.11779L6.94109 9.58847Z"/>
                                </svg>

                                At least 1 special character
                            </li>
                        </ul>

                        <Button className={"w-full font-inter text-[16px] leading-[20px] font-medium"}
                                size={'lg'}
                                variant={"lightGreen"}
                                type={'submit'}>Create account</Button>
                    </div>
                </form>
            </Form>

            <span className={'pt-6 flex flex-row gap-1 items-center'}>
                <span className={'font-inter text-[#222222] text-[14px] leading-[20px] font-normal'}>Already registered?</span>
                <span
                    className={"font-inter text-[#222222] text-[14px] leading-[20px] underline font-medium cursor-pointer"}
                    onClick={() => navigate(routes.main)}>
                    Sign In
                </span>
            </span>
        </div>
    } else return <h1 className={"text-foreground text-[2rem] font-medium"}>Token not found</h1>
}