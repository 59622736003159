import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {PhoneInput} from "src/components/phoneInput";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {editMember, PUTMember} from "src/utils/apiCalls/editMember";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {Role} from "src/utils/enums/role";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import formatEnumValueToString from "src/utils/stringHandlers/formatEnumValueToString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {Member} from "src/utils/types/structures/member";
import memberSchema from "src/utils/zodSchemas/memberSchema";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import * as z from "zod";

interface Props {
    member: Member
}

export default function EditMember({member}: Props) {
    const {mutateFirstSource, close} = useSheet();
    const {user} = useTokenStore();
    const navigate = useNavigate();
    const {width} = useViewport();
    const [isLoading, setLoading] = useState<boolean>(false);

    const form = useForm<z.infer<typeof memberSchema>>({
        resolver: zodResolver(memberSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            phone: '',
            role: '',
            email: ''
        }
    });

    useEffect(() => {
            form.reset()
            form.setValue('firstName', member.firstName);
            form.setValue('lastName', member.lastName);
            form.setValue('email', member.email);
            form.setValue('phone', member.phone);
            form.setValue('role', member.role);
    }, [member]);

    async function onSubmit(values: z.infer<typeof memberSchema>) {
            setLoading(true);

            const updatedMember: PUTMember = {
                ...values,
                id: member.id
            }

            const value = await editMember(updatedMember);
            if (isInstanceOf<ErrorMessage>(value, 'message')) {
                handleResponseError(value, () => navigate(routes.login))
            } else {
                close()
            }
            if (mutateFirstSource) {
                await mutateFirstSource()
            }
            setLoading(false)
    }

    return <section>
        <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col h-[calc(100dvh-4.25rem)]'}>
            <div className={'grow flex flex-col gap-5 pt-5 px-6'}>
                <div className={`${width <= defaultDimensions.sm ? 'flex flex-col gap-5' : 'flex gap-2 justify-between'}`}>
                    <FormField
                        control={form.control}
                        name="firstName"
                        render={({field}) => (
                            <FormItem>
                                <div className={'flex justify-between'}>
                                    <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>First name</FormLabel>
                                    {form.getFieldState('firstName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                </div>
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={"First name"}
                                        className={`text-base ${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}/>
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="lastName"
                        render={({field}) => (
                            <FormItem>
                                <div className={'flex justify-between'}>
                                    <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>Last name</FormLabel>
                                    {form.getFieldState('lastName').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                </div>
                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={"Last name"}
                                        className={`text-base ${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}
                                       />
                                </FormControl>

                            </FormItem>
                        )}
                    />
                </div>

                <FormField
                    control={form.control}
                    name="email"
                    render={({field}) => (
                        <FormItem>
                            <div className={'flex justify-between'}>
                                <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>Email</FormLabel>
                                {form.getFieldState('email').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                            </div>
                            <FormControl>
                                <Input
                                    {...field}
                                    disabled
                                    placeholder={"Email"}
                                    className={"text-base w-full"}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="phone"
                    render={({field}) => (
                        <FormItem>

                            <div className={'grid grid-cols-2'}>
                                <FormLabel className={'text-sm leading-3'}>Phone</FormLabel>
                                {form.getFieldState('phone').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                            </div>

                            <FormControl>
                                <PhoneInput
                                    {...field}
                                    isvalid={form.getFieldState('phone').invalid.toString()}
                                    placeholder={'Phone'}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="role"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Role</FormLabel>
                            <Select disabled={user?.email === member.email} onValueChange={field.onChange} value={field.value}>
                                <FormControl>
                                    <SelectTrigger className={'text-base rounded-lg'}>
                                        <SelectValue placeholder="Select role"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {Object.values(Role).map(
                                        el => <SelectItem key={el}
                                                          value={el}>{formatEnumValueToString(el)}</SelectItem>
                                    )}
                                </SelectContent>
                            </Select>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>

            <section
                className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
                <Button className={'w-full text-base font-medium leading-tight'}
                        variant={"outline"}
                        size={'lg'}
                        type={'button'}
                        onClick={(e) => {
                            e.preventDefault();
                            close()
                        }}
                >Cancel
                </Button>

                <Button className={'w-full text-base font-medium leading-tight'}
                        variant={"default"}
                        size={'lg'}
                        type={'submit'}
                >{isLoading ? <LoadingStatus/> : 'Save'}
                </Button>
            </section>
        </form>
    </Form>
    </section>
}