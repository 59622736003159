import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const MobileFleetIcon = ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 21 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.8332 17.5V15.8333C13.8332 14.9493 13.482 14.1014 12.8569 13.4763C12.2317 12.8512 11.3839 12.5 10.4998 12.5H5.49984C4.61578 12.5 3.76794 12.8512 3.14281 13.4763C2.51769 14.1014 2.1665 14.9493 2.1665 15.8333V17.5"
            stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M7.99984 9.16667C9.84079 9.16667 11.3332 7.67428 11.3332 5.83333C11.3332 3.99238 9.84079 2.5 7.99984 2.5C6.15889 2.5 4.6665 3.99238 4.6665 5.83333C4.6665 7.67428 6.15889 9.16667 7.99984 9.16667Z"
            stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M18.8335 17.501V15.8344C18.8329 15.0958 18.5871 14.3784 18.1346 13.7946C17.6821 13.2109 17.0486 12.794 16.3335 12.6094"
            stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M13.8335 2.60938C14.5505 2.79296 15.186 3.20996 15.6399 3.79463C16.0937 4.37931 16.34 5.0984 16.34 5.83854C16.34 6.57868 16.0937 7.29777 15.6399 7.88245C15.186 8.46712 14.5505 8.88412 13.8335 9.06771"
            stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}


export {MobileFleetIcon}