import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {PhoneInput} from "src/components/phoneInput";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {createMember} from "src/utils/apiCalls/createMember";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {Role} from "src/utils/enums/role";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import formatEnumValueToString from "src/utils/stringHandlers/formatEnumValueToString";
import {ErrorMessage} from "src/utils/types/errorMessage";
import memberSchema from "src/utils/zodSchemas/memberSchema";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import * as z from "zod";

export default function CreateMember() {
    const {mutateFirstSource, close, isOpen} = useSheet()
    const navigate = useNavigate();
    const {width} = useViewport();
    const [isLoading, setLoading] = useState<boolean>(false)

    const form = useForm<z.infer<typeof memberSchema>>({
        resolver: zodResolver(memberSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: Role.driver,
        }
    });

    useEffect(() => {
        form.reset()
    }, [isOpen]);

    async function onSubmit(values: z.infer<typeof memberSchema>) {
        setLoading(true);
        const value = await createMember(values);
        if (isInstanceOf<ErrorMessage>(value, 'message')) {
            handleResponseError(value, () => navigate(routes.login))
        }

        if (mutateFirstSource) {
            mutateFirstSource()
        }

        close()
        setLoading(false)
    }

    return <section>
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col h-[calc(100dvh-4.25rem)]'}>
                    <div className={'grow flex flex-col gap-5 pt-5 px-6'}>
                        <div className={`${width <= defaultDimensions.sm ? 'flex flex-col gap-5' : 'flex gap-2 justify-between'}`}>
                            <FormField
                                control={form.control}
                                name="firstName"
                                render={({field}) => (
                                    <FormItem>
                                        <div className={'flex justify-between'}>
                                            <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>First name</FormLabel>
                                            {form.getFieldState('firstName').invalid && <FormMessage
                                                className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                        </div>

                                        <FormControl>
                                            <Input
                                                {...field}
                                                placeholder={"First name"}
                                                className={`text-base ${form.getFieldState('firstName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}
                                                autoComplete={'off'}
                                            />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="lastName"
                                render={({field}) => (
                                    <FormItem>
                                        <div className={'flex justify-between'}>
                                            <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>Last
                                                name</FormLabel>
                                            {form.getFieldState('lastName').invalid && <FormMessage
                                                className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                        </div>

                                        <FormControl>
                                            <Input
                                                {...field}
                                                placeholder={"Last name"}
                                                autoComplete={'off'}
                                                className={`text-base ${form.getFieldState('lastName').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} flex-1`}/>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>


                        <FormField
                            control={form.control}
                            name="email"
                            render={({field}) => (
                                <FormItem>
                                    <div className={'flex justify-between'}>
                                        <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>Email</FormLabel>
                                        {form.getFieldState('email').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                    </div>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"Email"}
                                            autoComplete={'off'}
                                            className={`text-base ${form.getFieldState('email').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} w-full flex-1`}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="phone"
                            render={({field}) => (
                                <FormItem>

                                    <div className={'grid grid-cols-2'}>
                                        <FormLabel className={'text-sm leading-3'}>Phone</FormLabel>
                                        {form.getFieldState('phone').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm leading-3 font-medium place-self-end'}/>}
                                    </div>

                                    <FormControl>
                                        <PhoneInput
                                            {...field}
                                            className={'text-base'}
                                            isvalid={form.getFieldState('phone').invalid.toString()}
                                            placeholder={'Phone'}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="role"
                            render={({field}) => (
                                <FormItem>

                                    <div className={'flex justify-between'}>
                                        <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>Role</FormLabel>
                                        {form.getFieldState('role').invalid &&
                                            <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                    </div>

                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger
                                                className={`text-base ${form.getFieldState('role').invalid ? 'text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor' : 'text-foreground'} rounded-lg text-sm`}>
                                                <SelectValue className={'text-sm'} placeholder="Select role"/>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {Object.values(Role).map(
                                                el => <SelectItem key={el}
                                                                  value={el}>{formatEnumValueToString(el)}</SelectItem>
                                            )}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>

                <section
                    className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
                    <Button className={'w-full text-base font-medium leading-tight'}
                            variant={"outline"}
                            size={'lg'}
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                close()
                            }}
                    >Cancel
                    </Button>

                    <Button className={'w-full text-base font-medium leading-tight'}
                            variant={"default"}
                            size={'lg'}
                            type={'submit'}
                    >{isLoading ? <LoadingStatus/> : 'Send invite'}
                    </Button>
                </section>

            </form>
        </Form>
    </section>
}