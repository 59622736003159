import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button} from "src/components/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "src/components/select";
import {Textarea} from "src/components/textArea";
import useSheet from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import {PropertyType} from "src/utils/enums/addressEnum/propertyType";
import {Address} from "src/utils/types/structures/Address";
import addressSchema from "src/utils/zodSchemas/addressSchema";
import * as z from "zod";

interface Props {
    address: Address
}

type AddressProps = {
    floorNumber: boolean,
    numberStories: boolean,
    unitSize: boolean,
    propertySize: boolean,
    squareFootage: boolean,
}

export default function ChangeOrderAddress({address}: Props) {
    const [isLoading] = useState<boolean>(false);
    const [addressProps, setAddressProps] = useState<AddressProps | null>(null);
    const {close} = useSheet()

    const form = useForm<z.infer<typeof addressSchema>>({
        resolver: zodResolver(addressSchema),
        defaultValues: {
            address: '',
            propertySize: '',
            unitSize: '',
            squareFootage: '',
            numberStories: '',
            floorNumber: '',
            propertyType: '',
            state: '',
            unitNumber: '',
            access: '',
            direction: '',
            locality: '',
            zipCode: ''
        }
    });

    async function onSubmit(values: z.infer<typeof addressSchema>) {

    }


    useEffect(() => {
        form.setValue('address', address.address ?? '');
        form.setValue('propertySize', address.propertySize ?? '');
        form.setValue('unitSize', address.unitSize ?? '');
        form.setValue('numberStories', address.numberStories ?? '');
        form.setValue('floorNumber', address.floorNumber ?? '');
        form.setValue('propertyType', address.propertyType ?? '');
        form.setValue('state', address.state ?? '');
        form.setValue('unitNumber', address.unitNumber ?? '');
        form.setValue('access', address.access ?? '');
        form.setValue('direction', address.direction ?? '');
        form.setValue('locality', address.locality ?? '');
        form.setValue('zipCode', address.zipCode ?? '');

        switch (address.propertyType) {
            case PropertyType.apartment: {
                form.setValue("numberStories", null);
                form.setValue("unitSize", null);
                form.setValue("propertySize", address.propertySize ? address.propertySize : "");
                form.setValue("squareFootage", null);
                form.setValue("floorNumber", address.floorNumber ?? "");
                form.setValue("access", address.access ?? 'APARTMENT');
                setAddressProps({
                    floorNumber: true,
                    numberStories: false,
                    unitSize: false,
                    propertySize: true,
                    squareFootage: false
                });
                break
            }

            case PropertyType.house : {
                form.setValue("numberStories", address.numberStories ? address.numberStories : "");
                form.setValue("unitSize", null);
                form.setValue("propertySize", address.propertySize ? address.propertySize : "");
                form.setValue("squareFootage", null);
                form.setValue("floorNumber", "");
                form.setValue("access", address.access ?? 'HOUSE');
                setAddressProps({
                    floorNumber: false,
                    numberStories: true,
                    unitSize: false,
                    propertySize: true,
                    squareFootage: false
                });
                break
            }

            case PropertyType.storage: {
                form.setValue("numberStories", null);
                form.setValue("unitSize", address.unitSize ? address.unitSize : "");
                form.setValue("propertySize", null);
                form.setValue("squareFootage", null);
                form.setValue("floorNumber", address.floorNumber ?? "");
                form.setValue("access", address.access ?? 'STORAGE');
                setAddressProps({
                    floorNumber: true,
                    numberStories: false,
                    unitSize: true,
                    propertySize: false,
                    squareFootage: false
                });
                break
            }

            case PropertyType.commercial : {
                form.setValue("numberStories", null);
                form.setValue("unitSize", null);
                form.setValue("propertySize", null);
                form.setValue("squareFootage", address.squareFootage ? address.squareFootage : "");
                form.setValue("floorNumber", address.floorNumber ?? "");
                form.setValue("access", address.access ?? 'COMMERCIAL');
                setAddressProps({
                    floorNumber: true,
                    numberStories: false,
                    unitSize: false,
                    propertySize: false,
                    squareFootage: true
                })
                break
            }
        }
    }, [form, address.address, address.propertySize, address.unitSize, address.numberStories, address.floorNumber, address.propertyType, address.state, address.unitNumber, address.access, address.direction, address.locality, address.zipCode, address.squareFootage]);

    function onPropertyTypeChangeValue(e: string) {
        switch (e) {
            case "APARTMENT": {
                form.setValue("numberStories", null);
                form.setValue("unitSize", null);
                form.setValue("squareFootage", null);
                form.setValue("propertySize", "");
                form.setValue("floorNumber", "");
                form.setValue("access", "");
                setAddressProps({
                    floorNumber: true,
                    numberStories: false,
                    unitSize: false,
                    propertySize: true,
                    squareFootage: false
                });
                break
            }

            case "HOUSE" : {
                form.setValue("unitSize", null)
                form.setValue("squareFootage", null)
                form.setValue("floorNumber", "")
                form.setValue("propertySize", "")
                form.setValue("numberStories", "")
                form.setValue("access", "")
                setAddressProps({
                    floorNumber: false,
                    numberStories: true,
                    unitSize: false,
                    propertySize: true,
                    squareFootage: false
                });
                break
            }

            case "STORAGE": {
                form.setValue("numberStories", null);
                form.setValue("propertySize", null);
                form.setValue("squareFootage", null);
                form.setValue("unitSize", "");
                form.setValue("floorNumber", "");
                form.setValue("access", "");
                setAddressProps({
                    floorNumber: true,
                    numberStories: false,
                    unitSize: true,
                    propertySize: false,
                    squareFootage: false
                });
                break
            }

            case "COMMERCIAL" : {
                form.setValue("numberStories", null)
                form.setValue("unitSize", null)
                form.setValue("propertySize", null)
                form.setValue("squareFootage", "")
                form.setValue("floorNumber", "")
                form.setValue("access", "")
                setAddressProps({
                    floorNumber: true,
                    numberStories: false,
                    unitSize: false,
                    propertySize: false,
                    squareFootage: true
                });
                break
            }
        }
    }

    return <div className={'h-[calc(100dvh-4.25rem)] flex flex-col'}>

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={'flex-1 flex flex-col gap-4'}>

                <section className={"flex-1 flex flex-col gap-4 px-6 pt-5"}>
                    <FormField
                        control={form.control}
                        name="address"
                        render={({field}) => (
                            <FormItem inputMode={'text'}>
                                <div className={'grid grid-cols-2'}>
                                    <FormLabel className={'text-sm font-medium leading-tight'}>Address</FormLabel>
                                    {form.getFieldState('address').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor text-sm h-3 pb-5 grid place-items-end'}/>}
                                </div>

                                <FormControl>
                                    <Input
                                        {...field}
                                        placeholder={"Address"}
                                        className={`${form.getFieldState('address').invalid && 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'} text-foreground text-base font-normal leading-normal w-full`}
                                        autoComplete={'off'}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <div className={'flex gap-4'}>
                        <FormField
                            control={form.control}
                            name="unitNumber"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel className={'text-sm font-medium leading-tight'}>Apartment, suite, etc</FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"Apartment, suite, etc"}
                                            className={`${form.getFieldState('unitNumber').invalid && 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'} text-foreground text-base font-normal leading-normal w-full`}
                                            autoComplete={'off'}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="zipCode"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel className={'text-sm font-medium leading-tight'}>Zip</FormLabel>

                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"ZIP"}
                                            className={`${form.getFieldState('zipCode').invalid && 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'} text-foreground text-base font-normal leading-normal w-full`}
                                            autoComplete={'off'}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className={'flex gap-4'}>
                        <FormField
                            control={form.control}
                            name="locality"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel className={'text-sm font-medium leading-tight'}>City</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"City"}
                                            className={`${form.getFieldState('locality').invalid && 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'} text-foreground text-base font-normal leading-normal w-full`}
                                            autoComplete={'off'}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="state"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel className={'text-sm font-medium leading-tight'}>State</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder={"State"}
                                            className={`${form.getFieldState('state').invalid && 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'} text-foreground text-base font-normal leading-normal w-full`}
                                            autoComplete={'off'}/>
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <p className={'text-base font-medium leading-tight'}>Address property</p>

                    <section key={'properties-form'} className={'flex flex-col gap-4'}>
                        <div className={'flex gap-4'}>
                            <FormField
                                control={form.control}
                                name="propertyType"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel className={'text-sm font-medium leading-tight'}>Property type</FormLabel>
                                        <Select
                                            value={field.value ?? ""}
                                            onValueChange={(e) => {
                                                form.setValue("propertyType", e);
                                                onPropertyTypeChangeValue(e)
                                            }}
                                        >
                                            <FormControl>
                                                <SelectTrigger className={'text-base'}>
                                                    <SelectValue placeholder="Select value"/>
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value={PropertyType.apartment}>Apartment</SelectItem>
                                                <SelectItem value={PropertyType.house}>House</SelectItem>
                                                <SelectItem value={PropertyType.storage}>Storage</SelectItem>
                                                <SelectItem value={PropertyType.commercial}>Commercial</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />

                            {addressProps?.unitSize && <FormField
                                control={form.control}
                                name="unitSize"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel className={'text-sm font-medium leading-tight'}>Property size</FormLabel>
                                        <Select
                                            onValueChange={(e) => {
                                                form.setValue("unitSize", e);
                                            }}
                                            value={field.value ?? ""}>
                                            <FormControl>
                                                <SelectTrigger className={'text-base'}>
                                                    <SelectValue placeholder="Select value"/>
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem key="UNIT_5x5" value="UNIT_5x5">5`x 5`</SelectItem>
                                                <SelectItem key="UNIT_5x10" value="UNIT_5x10">5`x 10`</SelectItem>
                                                <SelectItem key="UNIT_10x10" value="UNIT_10x10">10`x 10`</SelectItem>
                                                <SelectItem key="UNIT_10x15" value="UNIT_10x15">10`x 15`</SelectItem>
                                                <SelectItem key="UNIT_10x20" value="UNIT_10x20">10`x 20`</SelectItem>
                                                <SelectItem key="UNIT_10x25" value="UNIT_10x25">10`x 25`</SelectItem>
                                                <SelectItem key="UNIT_10x30" value="UNIT_10x30">10`x 30`</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />}

                            {addressProps?.propertySize && <FormField
                                control={form.control}
                                name="propertySize"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel className={'text-sm font-medium leading-tight'}>Property size</FormLabel>
                                        <Select
                                            onValueChange={(e) => {
                                                form.setValue("propertySize", e);
                                            }}
                                            value={field.value ?? ""}>
                                            <FormControl>
                                                <SelectTrigger className={' text-base'}>
                                                    <SelectValue placeholder="Select value"/>
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem key="STUDIO" value="STUDIO">Studio</SelectItem>
                                                <SelectItem key="ONE_BEDROOM" value="ONE_BEDROOM">1 bedroom</SelectItem>
                                                <SelectItem key="TWO_BEDROOMS" value="TWO_BEDROOMS">2 bedrooms</SelectItem>
                                                <SelectItem key="THREE_BEDROOMS" value="THREE_BEDROOMS">3 bedrooms</SelectItem>
                                                <SelectItem key="FOUR_BEDROOMS" value="FOUR_BEDROOMS">4 bedrooms</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />}

                            {addressProps?.squareFootage && <FormField
                                control={form.control}
                                name="squareFootage"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel className={'text-sm font-medium leading-tight'}>Property size</FormLabel>
                                        <Select
                                            onValueChange={(e) => {
                                                form.setValue("squareFootage", e);
                                            }}
                                            value={field.value ?? ""}>
                                            <FormControl>
                                                <SelectTrigger className={'text-base'}>
                                                    <SelectValue placeholder="Select value"/>
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem key="SQ_FT_500_1000" value="SQ_FT_500_1000">500-1000 sq.ft</SelectItem>
                                                <SelectItem key="SQ_FT_1000_2000" value="SQ_FT_1000_2000">1000-2000 sq.ft</SelectItem>
                                                <SelectItem key="SQ_FT_2000_3000" value="SQ_FT_2000_3000">2000-3000 sq.ft</SelectItem>
                                                <SelectItem key="SQ_FT_3000_PLUS" value="SQ_FT_3000_PLUS">3000 sq.ft and more</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />}
                        </div>

                        <div className={'flex gap-4'}>
                            <FormField
                                control={form.control}
                                name="access"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <div className={'flex justify-between text-sm font-medium leading-tight py-1'}>
                                            <FormLabel className={'text-sm font-medium leading-tight'}>Access</FormLabel>
                                            {form.getFieldState('access').invalid && <FormMessage className={'text-marcoFormErrorTextColor text-sm font-medium leading-tight grid place-items-end'}/>}
                                        </div>
                                        <Select
                                            onValueChange={(e) => {
                                                form.clearErrors('access');
                                                form.setValue("access", e);
                                            }}
                                            value={field.value}>
                                            <FormControl>
                                                <SelectTrigger className={`${form.getFieldState('access').invalid && 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor bg-marcoFormBackgroundColor border-marcoFormErrorTextColor fill-marcoFormErrorTextColor'} text-base font-normal leading-normal w-full`}>
                                                    <SelectValue placeholder="Select value"/>
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem key="NO_STAIRS" value="NO_STAIRS">No stairs</SelectItem>
                                                <SelectItem key="ONE_FLIGHT_OF_STAIRS" value="ONE_FLIGHT_OF_STAIRS">1 flight of stairs</SelectItem>
                                                <SelectItem key="TWO_FLIGHT_OF_STAIRS" value="TWO_FLIGHT_OF_STAIRS">2 flight of stairs</SelectItem>
                                                <SelectItem key="THREE_FLIGHT_OF_STAIRS"
                                                            value="THREE_FLIGHT_OF_STAIRS">3 flight of stairs</SelectItem>
                                                <SelectItem key="FOUR_FLIGHT_OF_STAIRS"
                                                            value="FOUR_FLIGHT_OF_STAIRS">4 flight of stairs</SelectItem>
                                                <SelectItem key="FIVE_FLIGHT_OF_STAIRS"
                                                            value="FIVE_FLIGHT_OF_STAIRS">5 flight of stairs</SelectItem>
                                                <SelectItem key="SIX_FLIGHT_OF_STAIRS" value="SIX_FLIGHT_OF_STAIRS">6 flight of stairs</SelectItem>
                                                <SelectItem key="ELEVATOR" value="ELEVATOR">Elevator</SelectItem>
                                                <SelectItem key="DRIVE_UP" value="DRIVE_UP">Drive up</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />

                            {addressProps?.floorNumber && <FormField
                                control={form.control}
                                name="floorNumber"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel className={'text-sm font-medium leading-tight'}>Floor number</FormLabel>
                                        <FormControl>
                                            <Input
                                                className={`text-foreground text-base font-normal leading-normal w-full`}
                                                value={field.value}
                                                   onChange={(e) => {
                                                       form.setValue("floorNumber", e.target.value);
                                                   }}/>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />}

                            {addressProps?.numberStories && <FormField
                                control={form.control}
                                name="numberStories"
                                render={({field}) => (
                                    <FormItem className={'flex-1'}>
                                        <FormLabel className={'text-sm font-medium leading-tight'}>Stories</FormLabel>
                                        <Select
                                            onValueChange={(e) => {
                                                form.setValue("numberStories", e);
                                            }}
                                            value={field.value ?? ""}>
                                            <FormControl>
                                                <SelectTrigger className={'text-base'}>
                                                    <SelectValue placeholder="Select value"/>
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem key="ONE_STORIES" value="ONE_STORIES">1 story</SelectItem>
                                                <SelectItem key="TWO_STORIES" value="TWO_STORIES">2 stories</SelectItem>
                                                <SelectItem key="THREE_STORIES" value="THREE_STORIES">3 stories</SelectItem>
                                                <SelectItem key="FOUR_STORIES" value="FOUR_STORIES">4+ stories</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />}
                        </div>
                    </section>

                    <section className={'pt-2'}>
                        <p className={'text-foreground text-sm font-medium leading-tight'}>Address notes</p>
                        <p className={'text-muted-foreground text-sm font-normal leading-tight pt-1 pb-2'}>A description each space will help us determine scope and necessary expertise</p>
                        <Textarea placeholder={'Leave comments here...'}/>
                    </section>
                </section>

                <section
                    className={'flex-none sticky bottom-0 bg-white border-t z-40 flex justify-between py-4 px-6 gap-4'}>
                    <Button size={'lg'}
                            variant={'outline'}
                            className={'w-full text-base font-medium leading-tight'}
                            onClick={close}>Cancel</Button>
                    <Button
                        size={'lg'}
                        variant={'default'}
                        className={'w-full text-base font-medium leading-tight'}>{isLoading ?
                        <LoadingStatus/> : 'Save'}</Button>
                </section>
            </form>
        </Form>
    </div>
}