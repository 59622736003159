import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "src/components/button";
import LoadingStatus from "src/components/loadingStatus";
import {toast} from "src/components/useToast";
import {getImages} from "src/utils/apiCalls/getImages";
import {deleteFile} from "src/utils/apiCalls/orderDetails/attachments/deleteFile";
import {getURL, ImageUrl} from "src/utils/apiCalls/orderDetails/attachments/getURL";
import {updateUploadStatus} from "src/utils/apiCalls/orderDetails/attachments/updateUploadStatus";
import {uploadFile} from "src/utils/apiCalls/orderDetails/attachments/uploadFile";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {isInstanceOf} from "src/utils/errorHandlers/isInstanceOf";
import {ErrorMessage} from "src/utils/types/errorMessage";
import {OrderDetails} from "src/utils/types/structures/orderDetails";
import {OrderImage} from "src/utils/types/structures/orderImage";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import useOverlay from "src/utils/zustandStores/useOverlay";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    order: OrderDetails
    setOrder: React.Dispatch<React.SetStateAction<OrderDetails | undefined>>
    isLoading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function AttachmentsButtonSet({order, setOrder, isLoading, setLoading,}: Props) {
    const {id} = useParams();
    const navigate = useNavigate();
    const {isAnySelected, initStore, carrierImages, setSkeletonsCount} = useOrderAttachments();
    const [inputKey, setInputKey] = useState<string>(Math.random().toString(36));
    const {setProgressBarValue} = useOverlay()
    const {width} = useViewport();

    async function initiateFiles() {
        if (id){
            let updatedImages: OrderImage[] = []
            const images = await getImages(id)
            if (isInstanceOf<ErrorMessage>(images, 'message')) {
                handleResponseError(images, () => navigate(routes.login))
            } else {
                updatedImages = images
            }
            initStore(updatedImages, id)
        }
    }

    async function onDeleteImagesHandle() {
        setLoading(true)
        const ids: string[] = []

        carrierImages.forEach(el => {
            if  (el.isSelected) {
                ids.push(el.id)
            }
        })

        if (ids.length > 0) {
            const dataLoadPace = Number((100 / ids.length).toFixed(0));
            for (let i = 0; i < ids.length;) {
                const res = await deleteFile(ids[i])
                setProgressBarValue(useOverlay.getState().progressBarValue + dataLoadPace)
                if (res === 200) {
                    i++
                } else {
                    setProgressBarValue(0)
                    handleResponseError(res)
                    return
                }
            }
        }
        setProgressBarValue(100)
        await initiateFiles()
        window.scrollTo(0, 0)
        setLoading(false)
        setProgressBarValue(0)
    }

    async function onUploadImagesHandle(e: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true)

        if (e.target.files && order) {
            const dataLoadPace = Number((100 / e.target.files.length).toFixed(0));
            setSkeletonsCount(e.target.files.length)
            for (let i = 0; i < e.target.files.length;) {
                const url = await getURL(e.target.files[i], order.id)
                if (isInstanceOf<ImageUrl>(url, 'url')) {
                    const res = await uploadFile(e.target.files[i], url.url)
                    setProgressBarValue(useOverlay.getState().progressBarValue + dataLoadPace)
                    if (res === 200) {
                        await updateUploadStatus(url.id)
                        i++
                    } else {
                        setProgressBarValue(0)
                        toast({
                            variant: "destructive",
                            description: "Request failed",
                        })
                        return
                    }
                } else {
                    setProgressBarValue(0)
                    toast({
                        variant: "destructive",
                        description: "Request failed",
                    })
                    return
                }
            }

            setProgressBarValue(100)
            await initiateFiles()
            window.scrollTo(0, 0)
            setSkeletonsCount(0)
            setInputKey(Math.random().toString(36))
            setProgressBarValue(0)
        }
        setLoading(false)
    }

    if (isAnySelected) {
        return  <Button
            onClick={onDeleteImagesHandle}
            disabled={isLoading}
            variant={'red'}
            className={`${width < defaultDimensions.md ? 'h-[48px] text-white text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}>{isLoading ? <LoadingStatus/> : 'Delete selected'}</Button>
    } else return <div>
        <Button
            disabled={isLoading}
            className={`${width < defaultDimensions.md ? 'h-[48px] text-base font-medium  leading-[20px] w-full' : 'h-[40px] text-white text-sm font-medium leading-[20px]'}`}
            onClick={(e) => {
                const input = document.getElementById("fileInput");
                if (input) {input.click()}
            }}
        >{isLoading ? <LoadingStatus/> : 'Upload files'}</Button>

        <input className={"hidden"}
               type='file'
               key={inputKey}
               name={"files"}
               multiple
               accept="image/*, video/*, application/pdf"
               capture={false}
               id={"fileInput"}
               onChange={e => onUploadImagesHandle(e)}/>
    </div>

}