import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}


const MobileLoadsIcon= ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 21 20" fill="none">
        <path d="M18.125 13.3329V6.66626C18.1247 6.37399 18.0475 6.08693 17.9013 5.8339C17.755 5.58086 17.5448 5.37073 17.2917 5.22459L11.4583 1.89126C11.205 1.74498 10.9176 1.66797 10.625 1.66797C10.3324 1.66797 10.045 1.74498 9.79167 1.89126L3.95833 5.22459C3.70522 5.37073 3.49498 5.58086 3.34872 5.8339C3.20245 6.08693 3.1253 6.37399 3.125 6.66626V13.3329C3.1253 13.6252 3.20245 13.9123 3.34872 14.1653C3.49498 14.4183 3.70522 14.6285 3.95833 14.7746L9.79167 18.1079C10.045 18.2542 10.3324 18.3312 10.625 18.3312C10.9176 18.3312 11.205 18.2542 11.4583 18.1079L17.2917 14.7746C17.5448 14.6285 17.755 14.4183 17.9013 14.1653C18.0475 13.9123 18.1247 13.6252 18.125 13.3329Z" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.36719 5.83398L10.6255 10.0007L17.8839 5.83398" stroke="currentColor" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.625 18.3333V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export {MobileLoadsIcon}