import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
    stroke?: string
    strokeWidth?: string
}


const SearchIcon= ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 16 16">
        <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke={props.stroke ? props.stroke : "#222222"} strokeWidth="1.5"  strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.9996 13.9996L11.0996 11.0996" stroke={props.stroke ? props.stroke : "#222222"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export {SearchIcon}