import * as React from "react";
import {Button} from "src/components/button";
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popOver";
import useDialog, {DialogElementType} from "../../../layout/popUps/dialog/useDialog";
import {OrderDetails} from "../../../../../utils/types/structures/orderDetails";
import {PopoverClose} from "@radix-ui/react-popover";
import {ChevronDown} from "lucide-react";

type Props = {
    order: OrderDetails
}

export default function MoreActionsHandler({order} : Props) {
    const {open} = useDialog()

    return  <Popover>
        <PopoverTrigger asChild>
            <Button variant={'outline'} className={'h-[48px] md:h-[40px] text-[#222222] text-[16px] lg:text-[14px] flex flex-row items-center gap-4 leading-[20px] font-medium'}>
                <p>More actions</p>
                <ChevronDown className={'h-6 w-6 lg:h-5 lg:w-5'}/>
            </Button>
        </PopoverTrigger>
        <PopoverContent align={'end'} className="w-fit h-fit p-[5px]">
            <PopoverClose className={'hidden md:block p-0'}>
                <div
                    className={'p-2 rounded-[6px] w-full h-full bg-white hover:bg-[#F5F5F5] text-[#222222] text-[14px] leading-[20px] font-medium'}
                    onClick={() => {open(DialogElementType.contactSupport, order, 'Partner Support Assistance')}}>Partner support</div>
            </PopoverClose>
        </PopoverContent>
    </Popover>
}