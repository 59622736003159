import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}


const MobileSearchIcon= ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 21 20" fill="none">
        <path d="M10.0417 15.8333C13.7236 15.8333 16.7083 12.8486 16.7083 9.16667C16.7083 5.48477 13.7236 2.5 10.0417 2.5C6.35977 2.5 3.375 5.48477 3.375 9.16667C3.375 12.8486 6.35977 15.8333 10.0417 15.8333Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.375 17.5L14.75 13.875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export {MobileSearchIcon}