import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "src/components/button";
import MembersGrid from "src/pages/dashboarSection/fleetManagement/table/membersGrid";
import useSheet, {ElementType} from "src/pages/dashboarSection/layout/popUps/sheet/useSheet";
import fetcher from "src/utils/apiCalls/fetcher";
import {ApiHost} from "src/utils/defaults/apiHost";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import routes from "src/utils/defaults/routes";
import {handleResponseError} from "src/utils/errorHandlers/handleResponseError";
import {Member} from "src/utils/types/structures/member";
import useViewport from "src/utils/zustandStores/primary/useViewport";
import useSWR from "swr";

export default function FleetManagement() {
    const navigate = useNavigate();
    const {open} = useSheet()
    const {width, isScrolled} = useViewport();

    const {
        data: members, error, mutate: mutateMembers
    } = useSWR<Member[]>(ApiHost + encodeURI(`api/v1/company/user`), fetcher);

    useEffect(() => {
        if (error) {
            handleResponseError(error, () => navigate(routes.login))
        }
    }, [error]);

    if (members) {
        return  <>
            <div className={`px-padding ${width < defaultDimensions.md ? 'sticky top-0 pt-8' : 'pt-8'} ${!isScrolled && 'shadow-light'} md:shadow-none pb-6 bg-background z-30 flex justify-between items-center place-items-end`}>
                <section className={'w-full flex flex-row items-center justify-between'}>
                    <div className={'flex-1 flex-col gap-1'}>
                        <h2 className={'text-[#222222] text-[24px] leading-[32px] font-semibold'}>Manage team</h2>
                        <p className={"text-[#717171] text-[14px] leading-[16px] font-medium"}>{members.length} members</p>
                    </div>

                    <Button size={'default'}
                            variant={'secondary'}
                            className={'h-[48px] text-[#222222] text-[16px] leading-[20px] font-medium'}
                            onClick={() => open(ElementType.fleetManagementInviteMember, null, 'Invite a new member', () => mutateMembers())}>Add member</Button>
                </section>
            </div>

            <div className={'px-padding'}>
                <MembersGrid mutateMembers={() => mutateMembers()} members={members}/>
            </div>
        </>
    } else return null
}