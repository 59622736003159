import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Progress} from "src/components/progress";
import Header from "src/pages/dashboarSection/layout/header/header";
import AlertWrapper from "src/pages/dashboarSection/layout/popUps/alert/alertWrapper";
import DialogWrapper from "src/pages/dashboarSection/layout/popUps/dialog/dialogWrapper";
import ImageViewer from "src/pages/dashboarSection/layout/popUps/imageViewer/imageViewer";
import SheetWrapper from "src/pages/dashboarSection/layout/popUps/sheet/sheetWrapper";
import {defaultDimensions} from "src/utils/defaults/defaultDimensions";
import useOrderAttachments from "src/utils/zustandStores/orderDetailsAttachments/orderAttachments";
import useHeaderNavStore from "src/utils/zustandStores/primary/useHeaderNavStore";
import useTokenStore from "src/utils/zustandStores/primary/useTokenStore";
import useViewport, {ScrollPos} from "src/utils/zustandStores/primary/useViewport";
import useOverlay from "src/utils/zustandStores/useOverlay";

interface Props {
    children: React.ReactNode,
}

export default function LayoutMain({children}: Props) {
    const navigate = useNavigate();
    const [isHidden, setHidden] = useState<boolean>(false);
    const {menuBar, checkCurrentURL, initTabName} = useHeaderNavStore();
    const {setDimensions, setScrollHandler, scrollPos, width} = useViewport();
    const {isImageViewerOpen} = useOrderAttachments();
    const {progressBarValue} = useOverlay();
    const {user} = useTokenStore()

    useEffect(() => {
        checkCurrentURL(window.location.pathname)
        initTabName(window.location.pathname)
    }, [children]);

    useEffect(() => {
        window.addEventListener("resize", setDimensions);
        window.addEventListener('scroll', setScrollHandler);

        return () => {
            window.removeEventListener('scroll', setScrollHandler)
            window.removeEventListener("resize", setDimensions)
        };
    }, []);

    useEffect(() => {
        switch (scrollPos) {
            case ScrollPos.upDeadZone:
            case ScrollPos.up: {
                setHidden(false)
                break
            }

            case ScrollPos.down: {
                setHidden(true)
                break
            }
        }
    }, [scrollPos]);

    const shouldRender = () => {
        return !(window.location.pathname.includes('loads/') && !window.location.pathname.includes('loads/booked') && !window.location.pathname.includes('loads/in-progress') && !window.location.pathname.includes('loads/completed') && !window.location.pathname.includes('loads/all'));
    }

    return <div className={'h-dvh flex flex-col overflow-x-clip'}>
        <Progress className={"fixed top-0 z-[90]"} value={progressBarValue}/>
        <DialogWrapper/>
        <ImageViewer/>
        <SheetWrapper/>
        <AlertWrapper/>
        <Header/>

        <div className={`h-full flex flex-col w-full ${isImageViewerOpen && 'hidden'}`}>
            <section
                className={"flex-1 container max-w-[1728px] adaptiveContainer relative pb-24 md:pb-10"}>
                {children}

                {width < defaultDimensions.md && shouldRender() &&
                    <section className={`w-full ${isHidden ? 'absolute bottom-0' : 'fixed bottom-0'} z-30 pt-2`}>
                        <ul
                            className={`grid ${(user && user.roles === 'DRIVER') ? 'grid-cols-3' : 'grid-cols-5'} bg-background py-4 border-t`}>
                            {
                                user && menuBar
                                    .filter(el => el.mobile && el.permission.includes(user.roles))
                                    .map(el =>
                                        <li key={el.title}
                                            onClick={() => navigate(el.route)}
                                            className={`flex flex-col gap-1 cursor-pointer items-center px-[0.125rem] ${el.isActive ? 'text-foreground' : 'text-[#717171]'}`}>
                                            {el.icon}
                                            <p className={`text-sm ${el.isActive ? 'text-foreground font-medium' : 'text-[#717171]'}`}>{el.title}</p>
                                        </li>
                                    )
                            }
                        </ul>
                    </section>}
            </section>
        </div>
    </div>
}