import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
    size: number
}

const MobileInboxIcon = ({...props}: Props) => {
    return <svg {...props} width={props.size} height={props.size} viewBox="0 0 21 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 12.5C18 12.942 17.8244 13.366 17.5118 13.6785C17.1993 13.9911 16.7754 14.1667 16.3333 14.1667H6.33333L3 17.5V4.16667C3 3.72464 3.17559 3.30072 3.48816 2.98816C3.80072 2.67559 4.22464 2.5 4.66667 2.5H16.3333C16.7754 2.5 17.1993 2.67559 17.5118 2.98816C17.8244 3.30072 18 3.72464 18 4.16667V12.5Z"
            stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}


export {MobileInboxIcon}
