import React, {useEffect} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {UseFormReturn} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {Button} from "src/components/button";
import {Command} from "src/components/command";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {Slider} from "src/components/slider";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import {cn} from "src/utils/cn/cnHelper";
import {defaultFilterValues} from "src/utils/defaults/defaultFilterValues";
import searchAddresssInputSchema from "src/utils/zodSchemas/searchAddresssInputSchema";
import useSearch from "src/utils/zustandStores/useSearch";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import * as z from "zod";
import {LocationData} from "./mobileSearch";

interface Props {
    isLoading: boolean
    form: UseFormReturn<{ pickup: string, destination: string }, any, undefined>
    setLocationData: React.Dispatch<React.SetStateAction<LocationData>>
    sheetOpen: boolean
    setSheetOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function SearchInputMobile({
                                              isLoading,
                                              form,
                                              setLocationData,
                                              sheetOpen,
                                              setSheetOpen,
                                          }: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const {setOriginRadius, setDestinationRadius, response} = useSearch()

    const updateOrigin = () => {
        const originRadius = response.body.originRadius

        if (originRadius) {
            if (originRadius === defaultFilterValues.originRadius) {
                searchParams.delete('originRadius')
            } else searchParams.set('originRadius', originRadius.toString());

        } else searchParams.delete('originRadius')

        setSearchParams(searchParams)
    }

    const updateDestination = () => {
        const destinationRadius = response.body.destinationRadius

        if (destinationRadius) {
            if (destinationRadius === defaultFilterValues.destinationRadius) {
                searchParams.delete('destinationRadius')
            } else searchParams.set('destinationRadius', destinationRadius.toString());
        } else searchParams.delete('destinationRadius')

        setSearchParams(searchParams)
    }

    const {
        ready: originReady,
        value: originValue,
        suggestions: {status: originStatus, data: originData},
        setValue: setOriginValue,
        clearSuggestions: clearOriginSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['(regions)'],
            componentRestrictions: {country: "us"}
        },
        debounce: 300,
    });

    const {
        ready: destinationReady,
        value: destinationValue,
        suggestions: {status: destinationStatus, data: destinationData},
        setValue: setDestinationValue,
        clearSuggestions: clearDestinationSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['(regions)'],
            componentRestrictions: {country: "us"}
        },
        debounce: 300,
    });

    const onSubmit = async (values: z.infer<typeof searchAddresssInputSchema>) => {
        if (values.pickup) {
            const res = await getGeocode({address: values.pickup})
            const pickupGeocode = res[0]

            if (pickupGeocode) {
                const actualAddress = pickupGeocode.formatted_address.replace(', USA', '')
                searchParams.set('originAddress', actualAddress);
                setOriginValue(actualAddress, false);
                form.setValue('pickup', actualAddress);
                const {lat, lng} = getLatLng(pickupGeocode);
                setLocationData(prevState => {
                    return {
                        ...prevState, origin: {
                            shouldFetchData: false,
                            lat: lat,
                            lng: lng
                        }
                    }
                })
            } else {
                setLocationData(prevState => {
                    return {
                        ...prevState,
                        origin: {
                            shouldFetchData: false,
                            lat: null,
                            lng: null
                        }
                    }
                })
            }
        } else {
            setLocationData(prevState => {
                return {
                    ...prevState,
                    origin: {
                        shouldFetchData: false,
                        lat: null,
                        lng: null
                    }
                }
            })
        }

        if (values.destination) {
            const res = await getGeocode({address: values.destination})
            const destinationGeocode = res[0]
            if (destinationGeocode) {
                const actualAddress = destinationGeocode.formatted_address.replace(', USA', '')
                searchParams.set('destinationAddress', actualAddress);
                setDestinationValue(actualAddress, false);
                form.setValue('destination', actualAddress);
                const {lat, lng} = getLatLng(destinationGeocode);
                setLocationData(prevState => {
                    return {
                        ...prevState, destination: {
                            shouldFetchData: false,
                            lat: lat,
                            lng: lng
                        }
                    }
                })

            } else {
                setLocationData(prevState => {
                    return {
                        ...prevState,
                        destination: {
                            shouldFetchData: false,
                            lat: null,
                            lng: null
                        }
                    }
                })
            }
        } else {
            setLocationData(prevState => {
                return {
                    ...prevState, destination:
                        {
                        shouldFetchData: false,
                        lat: null,
                        lng: null
                    }
                }
            })
        }
        setSheetOpen(false)
        setSearchParams(searchParams);
    }

    const clearOrigin = useOnclickOutside(() => {
        clearOriginSuggestions();
    });

    const clearDestination = useOnclickOutside(() => {
        clearDestinationSuggestions();
    });

    useEffect(() => {
        if (sheetOpen) {
            const isDestInvalid = form.getFieldState('destination').invalid
            const isOriginInvalid = form.getFieldState('pickup').invalid

            if (isOriginInvalid) {
                form.setError('pickup', {message: 'Invalid location'})
            }

            if (isDestInvalid) {
                form.setError('destination', {message: 'Invalid location'})
            }

            setOriginValue(form.getValues('pickup'), false)
            setDestinationValue(form.getValues('destination'), false)
        }
    }, [sheetOpen]);
    const renderOriginSuggestions = () =>
        originData.map(
            (suggestion) => {

                const {
                    place_id,
                    structured_formatting: {main_text, secondary_text},
                } = suggestion;

                return (
                    <li ref={clearOrigin} key={place_id}
                        className={'cursor-pointer text-foreground hover:bg-marcoOnHover py-2 px-0'}
                        onClick={(event) => {
                            clearOriginSuggestions();
                            const addr = main_text + ", " + secondary_text
                            setOriginValue(addr.replace(', USA', ''), false);
                            form.setValue('pickup', addr.replace(', USA', ''));
                        }}>

                        <div className={'px-2 flex gap-3'}>
                            <div className="pl-2 p-1 flex-none place-self-center"><DotIcon size={8}
                                                                                       className={'fill-foreground'}/>
                            </div>
                            <span
                                className={'text-foreground text-base font-normal'}>
                            {main_text}, {secondary_text?.replace(', USA', '')}
                        </span>
                        </div>
                    </li>
                );
            });

    const renderDestinationSuggestions = () =>
        destinationData.map(
            (suggestion) => {

                const {
                    place_id,
                    structured_formatting: {main_text, secondary_text},
                } = suggestion;

                return (
                    <li ref={clearDestination} key={place_id}
                        className={'cursor-pointer text-foreground hover:bg-marcoOnHover py-2 px-0'}
                        onClick={(event) => {
                            clearDestinationSuggestions();
                            const addr = main_text + ", " + secondary_text
                            setDestinationValue(addr.replace(', USA', ''), false);
                            form.setValue('destination', addr.replace(', USA', ''));
                        }}>
                        <div className={'px-2 flex gap-3 place-items-center'}>
                            <div className="pl-2 p-1 flex-none fill-foreground">
                                <SquareIcon size={8} className={'fill-foreground'}/>
                            </div>
                            <span
                                className={'text-foreground text-base font-normal'}>
                            {main_text}, {secondary_text?.replace(', USA', '')}
                        </span>
                        </div>
                    </li>
                );
            });

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <section className={'flex-none sticky top-0 z-50 bg-white'}>
                <div className={'flex h-[4.25rem] justify-between py-4 px-4 items-center'}>
                    <h1 className={'text-[#222222] text-[20px] leading-[28px] font-semibold'}>Search for loads</h1>
                    <Button variant={'ghost'} onClick={() => {
                        setSheetOpen(false)
                    }} type={'button'} size={"iconFit"} className={'p-[0.375rem]'}>
                        <CrossIcon size={20} className={'fill-foreground'}/></Button>
                </div>
            </section>

            <div className={`flex flex-col relative w-full h-[calc(100dvh-4.25rem)]`}>
                <div className={'flex-1 px-4 pb-4 flex flex-col gap-6'}>
                    <FormField
                        control={form.control}
                        name="pickup"
                        render={({field}) => (
                            <FormItem className={'relative flex flex-col w-full'}>

                                <div className={'flex justify-between'}>
                                    <FormLabel htmlFor={'pickup-address'}
                                               className={'mb-2 h-3 text-[#222222] text-[14px] leading-[16px] font-medium'}>Origin</FormLabel>
                                    {form.getFieldState('pickup').invalid && <FormMessage
                                        className={'text-marcoFormErrorTextColor mb-2 h-3 text-sm font-medium leading-tight'}/>}
                                </div>

                                <FormControl>
                                    <Command
                                        className={`border rounded-md ${form.getFieldState('pickup').invalid && 'bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'}`}>
                                        <div className="flex items-center gap-2 pl-3 pr-2">
                                            <div>
                                                <div className={'p-1'}>
                                                    <DotIcon size={8}
                                                             className={`${form.getFieldState('pickup').invalid ? `fill-marcoFormErrorTextColor` : `fill-foreground`}`}/>
                                                </div>

                                            </div>
                                            <Input
                                                {...field}
                                                id={'pickup-address'}
                                                autoComplete={'off'}
                                                placeholder={"Anywhere"}
                                                className={`${form.getFieldState('pickup').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor' : 'text-foreground'} font-normal leading-tight px-0 border-none`}
                                                value={originValue}
                                                onChange={(e) => {
                                                    setOriginValue(e.target.value);
                                                    form.setValue('pickup', e.target.value)

                                                    if (e.target.value === '') {
                                                        searchParams.delete('originAddress')
                                                        setLocationData(prevState => {
                                                            return {
                                                                ...prevState, origin: {
                                                                    shouldFetchData: false,
                                                                    lat: null,
                                                                    lng: null
                                                                }
                                                            }
                                                        })
                                                        setSearchParams(searchParams)
                                                    }
                                                }}
                                                onInput={() => {
                                                    if (form.getFieldState('pickup').invalid) {
                                                        form.resetField('pickup')
                                                    }
                                                }}
                                                disabled={!originReady}
                                            />

                                            <div className={`${originValue ? '' : 'invisible'} cursor-pointer`}>
                                                <Button variant={'ghost'}
                                                        type={'button'}
                                                        size={'iconFit'}
                                                        className={`rounded-full p-1`}
                                                        onClick={() => {
                                                            setOriginValue('', false);
                                                            form.setValue('pickup', '');
                                                            form.clearErrors('pickup');
                                                            searchParams.delete('originAddress');
                                                            setLocationData(prevState => {
                                                                return {
                                                                    ...prevState, origin: {
                                                                        shouldFetchData: false,
                                                                        lat: null,
                                                                        lng: null
                                                                    }
                                                                }
                                                            });
                                                            setSearchParams(searchParams)
                                                        }}>
                                                    <CrossIcon size={16}
                                                               className={`${form.getFieldState('pickup').invalid ? 'fill-marcoFormErrorTextColor' : 'fill-foreground'}`}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </Command>
                                </FormControl>

                                {
                                    originStatus === "OK" &&
                                    <ul className={'text-sm border rounded-md w-full text-foreground absolute flex flex-col bg-white top-17 z-50'}>
                                        {renderOriginSuggestions()}
                                    </ul>
                                }
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="destination"
                        render={({field}) => (
                            <FormItem className={'relative flex flex-col w-full'}>

                                <div className={'flex justify-between'}>
                                    <FormLabel htmlFor={'destination-address'}
                                               className={'mb-2 h-3 text-[#222222] text-[14px] leading-[16px] font-medium'}>Destination</FormLabel>
                                    {form.getFieldState('destination').invalid &&
                                        <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                </div>

                                <FormControl>
                                    <Command
                                        className={`border rounded-md ${form.getFieldState('destination').invalid && 'bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'}`}>
                                        <div className="flex items-center gap-2 pl-3 pr-2">
                                            <div className={`p-1`}>
                                                <SquareIcon size={8}
                                                            className={`flex-none ${form.getFieldState('destination').invalid ? `fill-marcoFormErrorTextColor` : `fill-foreground`}`}/>
                                            </div>

                                            <Input
                                                {...field}
                                                id={'destination-address'}
                                                autoComplete={'off'}
                                                placeholder={"Anywhere"}
                                                className={`${form.getFieldState('destination').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor' : 'text-foreground'} border-none px-0`}
                                                value={destinationValue}
                                                onChange={(e) => {
                                                    setDestinationValue(e.target.value);
                                                    form.setValue('destination', e.target.value);
                                                    if (e.target.value === '') {
                                                        searchParams.delete('destinationAddress')
                                                        setLocationData(prevState => {
                                                            return {
                                                                ...prevState, destination: {
                                                                    shouldFetchData: false,
                                                                    lat: null,
                                                                    lng: null
                                                                }
                                                            }
                                                        })
                                                        setSearchParams(searchParams)
                                                    }
                                                }}
                                                onInput={() => {
                                                    if (form.getFieldState('destination').invalid) {
                                                        form.resetField('destination')
                                                    }
                                                }}
                                                disabled={!destinationReady}
                                            />

                                            <div className={`${destinationValue ? '' : 'invisible'} cursor-pointer`}>

                                                <Button variant={'ghost'}
                                                        size={'iconFit'}
                                                        className={`rounded-full p-1`}
                                                        type={'button'}
                                                        onClick={() => {
                                                            setDestinationValue('', false);
                                                            form.setValue('destination', '');
                                                            form.clearErrors('destination');
                                                            searchParams.delete('destinationAddress');
                                                            setLocationData(prevState => {
                                                                return {
                                                                    ...prevState, destination: {
                                                                        shouldFetchData: false,
                                                                        lat: null,
                                                                        lng: null
                                                                    }
                                                                }
                                                            })
                                                            setSearchParams(searchParams)
                                                        }}>

                                                    <CrossIcon size={16}
                                                               className={`${form.getFieldState('destination').invalid ? 'fill-marcoFormErrorTextColor' : 'fill-foreground'}`}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </Command>
                                </FormControl>

                                {destinationStatus === "OK" &&
                                    <ul className={'text-sm border rounded-md w-full absolute flex top-17 flex-col bg-white z-50'}>
                                        {renderDestinationSuggestions()}
                                    </ul>}
                            </FormItem>
                        )}
                    />

                    <section className={'flex flex-col gap-6 font-inter'}>

                        <h2 className={'text-[#222222] text-[16px] leading-[24px] font-medium'}>Radius</h2>


                        <div className={'flex flex-col font-inter border rounded-lg px-4 py-4 gap-6'}>
                            <div className={'flex flex-col gap-6'}>
                                <div className={'flex justify-between'}>
                                    <p className={'text-foreground text-sm font-inter'}>Pickup radius</p>
                                    <p className={'text-muted-foreground text-sm font-normal font-inter'}>Max {response.body.originRadius}{response.body.originRadius === defaultFilterValues.maxOriginRadius ? '+' : ''} mil</p>
                                </div>

                                <Slider
                                    value={[response.body.originRadius ?? 0]}
                                    onValueChange={(e) => {
                                        setOriginRadius(e[0]);
                                    }}
                                    max={defaultFilterValues.maxOriginRadius}
                                    min={defaultFilterValues.minOriginRadius}
                                    step={defaultFilterValues.pickupRadiusStep}
                                    className={cn("w-[100%]")}
                                    onPointerUp={updateOrigin}
                                />
                            </div>

                            <div className={'flex flex-col gap-6 pb-2'}>
                                <div className={'flex justify-between'}>
                                    <p className={'text-foreground text-sm font-inter'}>Delivery radius</p>
                                    <p
                                        className={'text-muted-foreground text-sm'}>Max {response.body.destinationRadius}{response.body.destinationRadius === defaultFilterValues.maxDestinationRadius ? '+' : ''} mil</p>
                                </div>

                                <Slider
                                    value={[response.body.destinationRadius ?? 0]}
                                    onValueChange={(e) => {
                                        setDestinationRadius(e[0])
                                    }}
                                    max={defaultFilterValues.maxDestinationRadius}
                                    min={defaultFilterValues.minDestinationRadius}
                                    step={defaultFilterValues.deliveryRadiusStep}
                                    className={cn("w-[100%]")}
                                    onPointerUp={updateDestination}
                                />
                            </div>
                        </div>
                    </section>
                </div>


                <section
                    className={'flex-none sticky bottom-0 bg-white border-t z-40 p-4 flex justify-between gap-4 items-center'}>

                    <Button className={'text-[#222222] text-[16px] leading-[20px] font-medium'}
                            variant={"textGhost"}
                            size={'lg'}
                            type={'button'}
                            onClick={() => {
                                setLocationData(
                                    {
                                        origin: {
                                            shouldFetchData: false,
                                            lng: null,
                                            lat: null,
                                        },

                                        destination: {
                                            shouldFetchData: false,
                                            lng: null,
                                            lat: null
                                        }
                                    }
                                )
                                searchParams.delete('destinationAddress')
                                searchParams.delete('originAddress')
                                searchParams.delete('originRadius')
                                searchParams.delete('destinationRadius')

                                form.setValue('destination', '')
                                form.setValue('pickup', '')
                                form.clearErrors();
                                setDestinationValue('', false)
                                setOriginValue('', false)
                                setOriginRadius(defaultFilterValues.originRadius)
                                setDestinationRadius(defaultFilterValues.destinationRadius)

                                setSearchParams(searchParams)
                            }}
                    >Clear all
                    </Button>

                    <Button
                        size={'lg'}
                        type={'submit'}
                        disabled={isLoading}
                        className={`rounded-md text-base font-medium leading-normal flex items-center`}
                    >
                        {isLoading ? <LoadingStatus/> : <div className={'flex gap-2 items-center'}>
                            <p className={'text-center text-white text-[16px] leading-[20px] font-medium'}>
                                Search
                            </p>

                        </div>}

                    </Button>
                </section>
            </div>
        </form>
    </Form>
}