import React, {useEffect} from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {UseFormReturn} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {Button} from "src/components/button";
import {Command} from "src/components/command";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "src/components/form";
import {Input} from "src/components/input";
import LoadingStatus from "src/components/loadingStatus";
import {CrossIcon} from "src/media/icons/custom/crossIcon";
import {DotIcon} from "src/media/icons/custom/dotIcon";
import {SquareIcon} from "src/media/icons/custom/squareIcon";
import {LocationData} from "src/pages/dashboarSection/search/searchLayout/mobileSearch/mobileSearch";
import searchAddresssInputSchema from "src/utils/zodSchemas/searchAddresssInputSchema";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import * as z from "zod";

interface Props {
    isLoading: boolean
    form: UseFormReturn<{ pickup: string, destination: string }, any, undefined>
    setLocationData: React.Dispatch<React.SetStateAction<LocationData>>

}

export default function SearchInputDesktop({
                                               isLoading,
                                               form,
                                               setLocationData,
                                           }: Props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const {
        ready: originReady,
        value: originValue,
        suggestions: {status: originStatus, data: originData},
        setValue: setOriginValue,
        clearSuggestions: clearOriginSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['(regions)'],
            componentRestrictions: {country: "us"}
        },
        debounce: 300,
    });

    const {
        ready: destinationReady,
        value: destinationValue,
        suggestions: {status: destinationStatus, data: destinationData},
        setValue: setDestinationValue,
        clearSuggestions: clearDestinationSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['(regions)'],
            componentRestrictions: {country: "us"}
        },
        debounce: 300,
    });

    const onSubmit = async (values: z.infer<typeof searchAddresssInputSchema>) => {
        if (values.pickup) {
            const res = await getGeocode({address: values.pickup})
            const pickupGeocode = res[0]

            if (pickupGeocode) {
                const actualAddress = pickupGeocode.formatted_address.replace(', USA', '')
                searchParams.set('originAddress', actualAddress);
                setOriginValue(actualAddress, false);
                form.setValue('pickup', actualAddress);
                const {lat, lng} = getLatLng(pickupGeocode);
                setLocationData(prevState => {
                    return {
                        ...prevState, origin: {
                            shouldFetchData: false,
                            lat: lat,
                            lng: lng
                        }
                    }
                })
            } else {
                setLocationData(prevState => {
                    return {
                        ...prevState,
                        origin: {
                            shouldFetchData: false,
                            lat: null,
                            lng: null
                        }
                    }
                })
            }
        } else {
            setLocationData(prevState => {
                return {
                    ...prevState,
                    origin: {
                        shouldFetchData: false,
                        lat: null,
                        lng: null
                    }
                }
            })
        }

        if (values.destination) {
            const res = await getGeocode({address: values.destination})
            const destinationGeocode = res[0]
            if (destinationGeocode) {
                const actualAddress = destinationGeocode.formatted_address.replace(', USA', '')
                searchParams.set('destinationAddress', actualAddress);
                setDestinationValue(actualAddress, false);
                form.setValue('destination', actualAddress);
                const {lat, lng} = getLatLng(destinationGeocode);
                setLocationData(prevState => {
                    return {
                        ...prevState, destination: {
                            shouldFetchData: false,
                            lat: lat,
                            lng: lng
                        }
                    }
                })

            } else {
                setLocationData(prevState => {
                    return {
                        ...prevState,
                        destination: {
                            shouldFetchData: false,
                            lat: null,
                            lng: null
                        }
                    }
                })
            }
        } else {
            setLocationData(prevState => {
                return {
                    ...prevState, destination:
                        {
                            shouldFetchData: false,
                            lat: null,
                            lng: null
                        }
                }
            })
        }
        setSearchParams(searchParams);
    }

    const clearOrigin = useOnclickOutside(() => {
        clearOriginSuggestions();
    });

    const clearDestination = useOnclickOutside(() => {
        clearDestinationSuggestions();
    });

    useEffect(() => {
        const origin = searchParams.get('originAddress')
        const destination = searchParams.get('destinationAddress')

        if (origin) {
            setOriginValue(origin, false)
        }

        if (destination) {
            setOriginValue(destination, false)
        }
    }, []);

    const renderOriginSuggestions = () =>
        originData.map(
            (suggestion) => {

                const {
                    place_id,
                    structured_formatting: {main_text, secondary_text},
                } = suggestion;

                return (
                    <li ref={clearOrigin} key={place_id}
                        className={'cursor-pointer text-foreground hover:bg-marcoOnHover py-2 px-2'}
                        onClick={(event) => {
                            clearOriginSuggestions();
                            const addr = main_text + ", " + secondary_text
                            setOriginValue(addr.replace(', USA', ''), false);
                            form.setValue('pickup', addr.replace(', USA', ''));
                        }}>

                        <div className={'px-0 flex gap-3'}>

                            <div className="flex-none place-self-center pl-2 p-1">
                                <DotIcon size={8} className={'fill-foreground'}/>
                            </div>

                            <span
                                className={'text-foreground text-base font-normal'}>
                            {main_text}, {secondary_text?.replace(', USA', '')}
                        </span>
                        </div>
                    </li>
                );
            });

    const renderDestinationSuggestions = () =>
        destinationData.map(
            (suggestion) => {

                const {
                    place_id,
                    structured_formatting: {main_text, secondary_text},
                } = suggestion;

                return (
                    <li ref={clearDestination} key={place_id}
                        className={'cursor-pointer text-foreground hover:bg-marcoOnHover py-2 px-0'}
                        onClick={(event) => {
                            clearDestinationSuggestions();
                            const addr = main_text + ", " + secondary_text
                            setDestinationValue(addr.replace(', USA', ''), false);
                            form.setValue('destination', addr.replace(', USA', ''));
                        }}>
                        <div className={'px-2 flex gap-3 place-items-center'}>
                            <div className="pl-2 p-1 flex-none fill-foreground">
                                <SquareIcon size={8} className={'fill-foreground'}/>
                            </div>

                            <span
                                className={'text-foreground text-base font-normal'}>
                            {main_text}, {secondary_text?.replace(', USA', '')}
                        </span>
                        </div>
                    </li>
                );
            });

    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={`font-inter gap-2 md:gap-4 flex items-end relative`}>

                <div className={'w-full flex gap-2 md:gap-4'}>
                    <FormField
                        control={form.control}
                        name="pickup"
                        render={({field}) => (
                            <FormItem
                                className={'flex-1 relative flex flex-col sm:max-w-[18.313rem] md:max-w-[24.813rem] lg:max-w-[23.438rem]'}>

                                <div className={'flex justify-between'}>
                                    <FormLabel className={'text-sm mb-2 h-3 text-foreground'}>Pickup</FormLabel>
                                    {form.getFieldState('pickup').invalid &&
                                        <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                </div>

                                <FormControl>
                                    <Command
                                        className={`flex px-3 h-12 justify-between border rounded-md ${form.getFieldState('pickup').invalid && 'bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'}`}>

                                        <div className={'flex-1 flex gap-3 items-center'}>
                                            <div className={`flex-none p-1`}>
                                                <DotIcon size={8}
                                                         className={`${form.getFieldState('pickup').invalid ? `fill-marcoFormErrorTextColor` : `fill-foreground`}`}/>
                                            </div>

                                            <Input
                                                {...field}
                                                id={'pickup-address'}
                                                autoComplete={'off'}
                                                placeholder={"Anywhere"}
                                                className={`${form.getFieldState('pickup').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor' : 'text-foreground'} px-0 border-none`}
                                                value={originValue}
                                                onChange={(e) => {
                                                    setOriginValue(e.target.value);
                                                    form.setValue('pickup', e.target.value)

                                                    if (e.target.value === '') {
                                                        searchParams.delete('originAddress')
                                                        setLocationData(prevState => {
                                                            return {
                                                                ...prevState, origin: {
                                                                    shouldFetchData: false,
                                                                    lat: null,
                                                                    lng: null
                                                                }
                                                            }
                                                        })
                                                        setSearchParams(searchParams)
                                                    }
                                                }}
                                                onInput={() => {
                                                    if (form.getFieldState('pickup').invalid) {
                                                        form.resetField('pickup')
                                                    }
                                                }}
                                                disabled={!originReady}
                                            />

                                            <div className={`flex-none ${originValue ? '' : 'invisible'} cursor-pointer`}
                                                 onClick={() => {
                                                     setOriginValue('');
                                                     form.setValue('pickup', '')
                                                 }}>
                                                <Button variant={'ghost'}
                                                        size={'iconFit'}
                                                        className={`rounded-full p-1`}
                                                        onClick={() => {
                                                            setOriginValue('', false);
                                                            form.setValue('pickup', '');
                                                            form.clearErrors('pickup');
                                                            searchParams.delete('originAddress');
                                                            setLocationData(prevState => {
                                                                return {
                                                                    ...prevState, origin: {
                                                                        shouldFetchData: false,
                                                                        lat: null,
                                                                        lng: null
                                                                    }
                                                                }
                                                            });
                                                            setSearchParams(searchParams)
                                                        }}>
                                                    <CrossIcon size={16}
                                                               className={`${form.getFieldState('pickup').invalid ? 'fill-marcoFormErrorTextColor' : 'fill-foreground'}`}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </Command>
                                </FormControl>
                                {originStatus === "OK" &&
                                    <ul className={'text-sm mt-3 border rounded-md w-full text-foreground absolute flex flex-col top-17 bg-white z-50'}>
                                        {renderOriginSuggestions()}
                                    </ul>}
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="destination"
                        render={({field}) => (
                            <FormItem
                                className={'flex-1 relative flex flex-col sm:max-w-[18.313rem] md:max-w-[24.813rem] lg:max-w-[23.438rem]'}>

                                <div className={'flex justify-between'}>
                                    <FormLabel htmlFor={'destination-address'}
                                               className={'text-sm mb-2 h-3 text-foreground'}>Destination</FormLabel>
                                    {form.getFieldState('destination').invalid &&
                                        <FormMessage className={'text-marcoFormErrorTextColor text-sm mb-2 h-3'}/>}
                                </div>

                                <FormControl>
                                    <Command
                                        className={`flex px-3 h-12 justify-between border rounded-md ${form.getFieldState('destination').invalid && 'bg-marcoFormBackgroundColor border-marcoFormErrorTextColor'}`}>
                                        <div className={'flex-1 flex gap-3 items-center'}>
                                            <div className={`flex-none p-1`}>
                                                <SquareIcon size={8}
                                                            className={`flex-none ${form.getFieldState('destination').invalid ? `fill-marcoFormErrorTextColor` : `fill-foreground`}`}/>
                                            </div>

                                            <Input
                                                {...field}
                                                autoComplete={'off'}
                                                placeholder={"Anywhere"}
                                                className={`${form.getFieldState('destination').invalid ? 'placeholder:text-marcoFormErrorTextColor text-marcoFormErrorTextColor' : 'text-foreground'} border-none px-0`}
                                                value={destinationValue}
                                                onChange={(e) => {
                                                    setDestinationValue(e.target.value);
                                                    form.setValue('destination', e.target.value);
                                                    if (e.target.value === '') {
                                                        searchParams.delete('destinationAddress')
                                                        setLocationData(prevState => {
                                                            return {
                                                                ...prevState, destination: {
                                                                    shouldFetchData: false,
                                                                    lat: null,
                                                                    lng: null
                                                                }
                                                            }
                                                        })
                                                        setSearchParams(searchParams)
                                                    }
                                                }}
                                                onInput={() => {
                                                    if (form.getFieldState('destination').invalid) {
                                                        form.resetField('destination')
                                                    }
                                                }}
                                                disabled={!destinationReady}

                                            />

                                            <div className={`flex-none ${destinationValue ? '' : 'invisible'} cursor-pointer`}
                                                 onClick={() => {
                                                     setDestinationValue('');
                                                     form.setValue('destination', '')
                                                 }}>
                                                <Button variant={'ghost'}
                                                        size={'iconFit'}
                                                        className={`rounded-full p-1`}
                                                        type={'button'}
                                                        onClick={() => {
                                                            setDestinationValue('', false);
                                                            form.setValue('destination', '');
                                                            form.clearErrors('destination');
                                                            searchParams.delete('destinationAddress');
                                                            setLocationData(prevState => {
                                                                return {
                                                                    ...prevState, destination: {
                                                                        shouldFetchData: false,
                                                                        lat: null,
                                                                        lng: null
                                                                    }
                                                                }
                                                            })
                                                            setSearchParams(searchParams)
                                                        }}>
                                                    <CrossIcon size={16}
                                                               className={`${form.getFieldState('destination').invalid ? 'fill-marcoFormErrorTextColor' : 'fill-foreground'}`}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </Command>
                                </FormControl>

                                {destinationStatus === "OK" &&
                                    <ul className={'text-sm mt-3 border rounded-md w-full absolute flex flex-col top-17 bg-white z-50'}>
                                        {renderDestinationSuggestions()}
                                    </ul>}
                            </FormItem>
                        )}
                    />

                    <Button
                        size={'lg'}
                        type={'submit'}
                        disabled={isLoading}
                        className={`flex-none rounded-md text-[1rem] w-full ${isLoading ? 'sm:w-[7.375rem]' : 'sm:w-[6.375rem]'} sm:mt-6`}
                    >{isLoading ? <LoadingStatus/> : 'Search'}</Button>
                </div>
            </div>
        </form>
    </Form>
}